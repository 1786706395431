
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class TopBar extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) subTitle!: string
  @Prop({ default: true }) hasBreadcrumb!: boolean

  get groupId() {
    return this.$route.params?.groupId
  }

  get breadcrumbs() {
    return this.$route.meta.breadcrumb ? this.$route.meta.breadcrumb : []
  }
}
