<template>
  <q-space class="q-my-md" />
  <TopBar v-if="selectingApp" :title="title" />
  <router-view />
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IApp } from '@/utils/types'

import AppMixin from '@/components/app/mixins/AppMixin.vue'
import TopBar from '@/components/common/ui/TopBar.vue'
import { ACTION_APP } from '@/store/actions'
import { Watch } from 'vue-property-decorator'

@Options({
  components: {
    TopBar,
  },
  directives: { maska },
})
export default class AppDetails extends mixins(AppMixin) {
  selectingApp: IApp = {}
  title = ''

  get story() {
    return this.$store.getters.story
  }

  get metaTitle() {
    return this.$route.meta?.title
  }

  async fetchSelectingApp() {
    if (!this.appId) {
      this.selectingApp = {}
      return
    }
    this.selectingApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.appId)
    return this.$route.params.app_id
  }

  @Watch('$route.name', { immediate: true })
  @Watch('selectingApp', { immediate: true })
  @Watch('story', { immediate: true })
  changeTitle() {
    this.title = this.selectingApp.name ?? ''
    if (this.$route.name === 'story_setting' && this.$route?.params?.story_id) {
      if (this.story.story_name) {
        this.title = this.story.story_name
      }
    } else if (this.metaTitle && this.metaTitle !== '') {
      this.title = this.$t(this.metaTitle)
    }
  }

  async created() {
    // [AppMixin]
    await this.fetchSelectingApp()
  }
}
</script>
