import { render } from "./TopBar.vue?vue&type=template&id=2bbe1d4a"
import script from "./TopBar.vue?vue&type=script&lang=ts"
export * from "./TopBar.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBreadcrumbs,QBreadcrumbsEl});
