<template>
  <div class="flex justify-between q-mb-md">
    <div>
      <q-breadcrumbs gutter="xs" v-if="hasBreadcrumb">
        <q-breadcrumbs-el :label="$t('label.dashboard')" :to="{ name: 'overview' }" />
        <q-breadcrumbs-el
          v-for="breadcrumb in breadcrumbs"
          :key="breadcrumb.name"
          :label="$t(breadcrumb.label)"
          :to="{ name: breadcrumb.name }"
        />
        <q-breadcrumbs-el v-if="breadcrumbs.length === 0" />
      </q-breadcrumbs>
      <h1 class="text-h5 text-bold topbar-title">{{ title }}</h1>
      <p class="text-subtitle2 text-grey-8" v-if="subTitle">{{ subTitle }}</p>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class TopBar extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) subTitle!: string
  @Prop({ default: true }) hasBreadcrumb!: boolean

  get groupId() {
    return this.$route.params?.groupId
  }

  get breadcrumbs() {
    return this.$route.meta.breadcrumb ? this.$route.meta.breadcrumb : []
  }
}
</script>
